/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 318px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 50%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB5nLUthB//8QAGxAAAgEFAAAAAAAAAAAAAAAAABIBAgMQIUH/2gAIAQEAAQUCUUp1jtuZX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAEEAwAAAAAAAAAAAAAAAAAyQVGSASMx/9oACAEBAAY/AlyLgzscsVOuf//EABwQAAICAgMAAAAAAAAAAAAAAAABEUEhMaGx8f/aAAgBAQABPyFY54SPnQsEisqRLjdGXZ7Ng27P/9oADAMBAAIAAwAAABCnH//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxCrr//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EIf/xAAfEAEAAgEEAwEAAAAAAAAAAAABABFRIUFxgTFhsfD/2gAIAQEAAT8QFLQ7a3d7zFbj+KmjhVC67XXuZrlz5h9x4IJgBl9p/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"heinz 57a\"\n        title=\"heinz 57a\"\n        src=\"/static/ff972a01f1ba3392cf7c4a87972e7a99/1f18d/heinz-57a.jpg\"\n        srcset=\"/static/ff972a01f1ba3392cf7c4a87972e7a99/4d5fb/heinz-57a.jpg 166w,\n/static/ff972a01f1ba3392cf7c4a87972e7a99/1f18d/heinz-57a.jpg 318w\"\n        sizes=\"(max-width: 318px) 100vw, 318px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "A Heinz 57 variety"), "\n", React.createElement(_components.p, null, "Starting the company from the ground up, Henry John Heinz formed the H.J. Heinz Company in 1876. Though he was trained as a brick layer he decided to go into the food industry. Being very aware of the power of good advertising Heinz gained a lot of brand recognition in the early days by giving away a pickle shaped watch charm. The company was able to ride the momentum of their notable brand recognition to success all the while introducing new sauces to their line-up. It wasn’t until 1896 that Heinz noticed a shoe seller advertising “21 styles of shoes” and decided to advertise Heinz in a similar fashion. Though at the time Heinz offered over 60 flavors the number 57 was settled on as the slogan. From that point on the bottle read “Heinz 57 varieties”."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
